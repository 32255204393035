<template>
  <Layout class="main-container">
    <!--    搜索块-->
    <Form
      ref="SearchForm"
      class="search-form-container"
      :model="params"
      label-position="left"
      :label-width="70"
    >
      <div class="search-form-container__content">
        <Row :gutter="16">
          <Col span="6">
            <FormItem label="登陆账号:" prop="UserName">
              <Input
                placeholder="请输入登录账号"
                v-model="params.UserName"
                clearable
              />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="手机号码:" prop="MobilePhone">
              <Input
                placeholder="请输入手机号码"
                v-model="params.MobilePhone"
                clearable
              />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="真实姓名:" prop="RealName">
              <Input
                placeholder="请输入真实姓名"
                v-model="params.RealName"
                clearable
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <Row type="flex" justify="space-between" align="bottom" class="mt5">
        <Col span="12" style="text-align: left">
          <Button
            type="success"
            icon="md-add-circle"
            v-if="$canAction(['Sys_User_Edit'])"
            @click="handleOpenAdd"
          >
            新增
          </Button>
        </Col>
        <Col span="12" style="text-align: right">
          <span
            class="search-form-container__arrow_btn"
            @click="$handleHiddenSearch($el)"
          >
            收起
            <Icon type="ios-arrow-up" />
          </span>
          <Button
            class="ml2 search-form-container__button"
            type="primary"
            ghost
            icon="ios-search"
            @click="handleSearch"
          >
            搜索
          </Button>
          <Button
            class="ml2 search-form-container__button"
            @click="handleReset"
          >
            重置
          </Button>
        </Col>
      </Row>
    </Form>
    <!--    table-->
    <Table
      stripe
      class="mt10 main-table"
      :loading="tableLoading"
      :height="tableHeight"
      ref="dataTable"
      :columns="columns"
      @on-sort-change="sortChange"
      :data="resObj.DataList"
      highlight-row
      size="small"
    >
      <template slot="Image" slot-scope="{ row }">
        <Avatar
          :src="$setImagePrefix(row.Avatar)"
          style="min-width: 31px; height: 31px; margin-top: 3px"
        />
      </template>
      <template slot="Status" slot-scope="{ row }">
        <Tag
          type="border"
          :color="
            row.Status === 10
              ? 'primary'
              : row.Status === 20
              ? 'red'
              : 'default'
          "
        >
          {{ row.StatusCN }}
        </Tag>
      </template>
      <template slot="Action" slot-scope="{ row }">
        <Button
          size="small"
          type="success"
          class="ml2"
          v-if="$canAction(['Sys_User_Edit'])"
          @click="handleOpenEdit(row)"
        >
          编辑
        </Button>
        <Button
          size="small"
          type="primary"
          class="ml2"
          v-if="$canAction(['Sys_User_SetRole'])"
          @click="handleOpenRoles(row)"
        >
          分配角色
        </Button>
      </template>
    </Table>
    <!--    分页-->
    <Row class="mt10">
      <Col span="24" class="flex-end">
        <Page
          :total="resObj.TotalCount"
          :current="resObj.CurrentPage"
          @on-page-size-change="handleChangePageSize"
          @on-change="handleChangePage"
          size="small"
          show-elevator
          show-sizer
          show-total
        />
      </Col>
    </Row>
    <Modal v-model="setView" title="分配角色" class-name="i-modal">
      <CheckboxGroup @on-change="selectChangeList" v-model="NowCheckList">
        <Checkbox
          class="s_u_modal_box"
          v-for="(item, index) in allRoleList"
          :key="index"
          :label="item.RoleID"
        >
          {{ item.RoleName }}
        </Checkbox>
      </CheckboxGroup>
      <div slot="footer">
        <Button type="default" @click="setView = false"> 取消 </Button>
        <Button type="primary" @click="handleSaveRoles" :loading="SaveLoading">
          {{ !SaveLoading ? "保存" : "保存中..." }}
        </Button>
      </div>
    </Modal>
    <Modal v-model="userView" title="编辑用户信息" class-name="i-modal">
      <Form
        ref="FormUser"
        :model="selectedObj"
        class="modelViewFromCheck"
        :rules="ruleValidate"
        :label-width="85"
        label-position="right"
      >
        <FormItem label="用户头像:">
          <i-upload
            :fileKey="uploadType.Avatar"
            @onChange="onChange"
            ref="UploadAvatar"
            :max="1"
          ></i-upload>
        </FormItem>
        <Row :gutter="16">
          <Col span="12">
            <FormItem prop="ID" label="用户编号">
              <Input type="text" v-model="selectedObj.ID" disabled></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem prop="MobilePhone" label="手机号">
              <Input type="text" v-model="selectedObj.MobilePhone"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="16">
          <Col span="12">
            <FormItem prop="RealName" label="真实姓名">
              <Input type="text" v-model="selectedObj.RealName"></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="用户状态:" prop="Status">
              <Select transfer v-model="selectedObj.Status">
                <Option :value="10">启用</Option>
                <Option :value="20">禁用</Option>
                <Option :value="-99">删除</Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="16">
          <Col span="12">
            <FormItem prop="UserName" label="登录账号">
              <Input type="text" v-model="selectedObj.UserName"></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem prop="UserPwd" label="登录密码">
              <Input type="password" v-model="selectedObj.UserPwd"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="16">
          <Col span="24">
            <FormItem prop="Email" label="邮箱地址">
              <Input type="text" v-model="selectedObj.Email"></Input>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <div slot="footer">
        <Button @click="userView = false">取消</Button>
        <Button type="primary" @click="handleSaveAll" :loading="SaveLoading">
          <span v-if="!SaveLoading">保存</span>
          <span v-else>不用等一万年，马上就好...</span>
        </Button>
        <!-- <Button v-if="!isEdit" type="primary" @click="Ok" :loading="SaveLoading" :disabled="!AddCheckUsecase">
          <span v-if="!SaveLoading">新增</span>
          <span v-else>不用等一万年，马上就好...</span>
        </Button>-->
      </div>
    </Modal>
  </Layout>
</template>

<script>
import layout from "@/components/layout/index.vue";
import api from "@/api";
import bizEnum from "@/libs/bizEnum";
import iUpload from "@/components/upload/index.vue";
export default {
  name: "UserManage",
  data() {
    // 密码验证拓展
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("登录密码不能为空"));
      } else {
        callback();
      }
    };
    const validateMobile = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("手机号不能为空"));
      }
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("请输入有效的手机号"));
      } else {
        callback();
      }
    };
    return {
      tableLoading: false,
      tableHeight: 0,
      resObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
      uploadFile: [],
      setView: false,
      SaveLoading: false,
      userView: false,
      old: null,
      selectedObj: {},
      allRoleList: [],
      NowCheckListSource: [],
      NowCheckList: [],
      AddCheckList: [], // 新增的选项
      DelCheckList: [], // 移除的选项
      params: {
        UserName: "",
        MobilePhone: "",
        RealName: "",
      },
      columns: [
        {
          title: "用户图标",
          key: "Logo",
          minWidth: 90,
          slot: "Image",

          align: "center",
        },
        {
          title: "编号",
          key: "ID",
          ellipsis: true,
          minWidth: 60,
          align: "center",
          sortable: "custom",
        },
        {
          title: "帐号",
          key: "UserName",
          ellipsis: true,
          minWidth: 95,
          align: "center",
          sortable: "custom",
        },
        {
          title: "手机号码",
          key: "MobilePhone",
          ellipsis: true,
          minWidth: 95,
          align: "center",
          sortable: "custom",
        },
        {
          title: "姓名",
          key: "RealName",
          ellipsis: true,
          minWidth: 95,
          align: "center",
        },
        {
          title: "创建时间",
          key: "CreateDate",
          minWidth: 135,
          ellipsis: true,
          align: "center",
          sortable: "custom",
        },
        {
          title: "状态",
          key: "Status",

          slot: "Status",
          align: "center",
          minWidth: 90,
          sortable: "custom",
        },
      ],
      ruleValidate: {
        RealName: [
          { required: true, message: "真实姓名不能为空", trigger: "blur" },
        ],
        UserName: [
          { required: true, message: "登录账号能为空", trigger: "change" },
        ],
        UserPwd: [
          { required: true, message: "登录密码不能为空", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
        MobilePhone: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          { validator: validateMobile, trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    userView: function (val) {
      if (val) {
        if (this.selectedObj.Avatar) {
          this.uploadFile = this.$refs.UploadAvatar.uploadFiles = [
            {
              _key: this.selectedObj.Avatar,
              _file: this.$setImagePrefix(this.selectedObj.Avatar),
              success: true,
            },
          ];
        } else {
          this.uploadFile = [];
          this.$refs.UploadAvatar.uploadFiles = [];
        }
      }
    },
  },
  methods: {
    async loadList() {
      this.tableLoading = true;
      try {
        const res = await api.GetUserList(this.params);
        if (res.Status === 100) {
          this.resObj = res.Data;
          this.tableLoading = false;
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
        // TODO: 记录错误日志
      }
    },
    handleOpenEdit(row) {
      this.$refs.FormUser.resetFields();
      this.selectedObj = JSON.parse(JSON.stringify(row));
      this.old = JSON.parse(JSON.stringify(row));
      this.userView = true;
    },
    async handleOpenAdd() {
      try {
        this.$refs.FormUser.resetFields();
        this.selectedObj = {
          ID: "",
          UserName: "",
          MobilePhone: "",
          RealName: "",
          Email: "",
          Status: 10,
          UserPwd: "",
          isNew: true,
        };
        this.userView = true;
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
      }
    },
    handleSaveAll() {
      this.$refs["FormUser"].validate(async (valid) => {
        if (valid) {
          if (this.selectedObj.isNew) {
            this.SaveLoading = true;
            try {
              const res = await api.SaveUser({
                user: this.selectedObj,
              });
              if (res.Status === 100) {
                this.SaveLoading = false;
                this.$Message.success("添加成功");
                this.userView = false;
                this.resObj.DataList.push(res.Data);
              } else {
                this.$Notice.error({
                  title: "错误",
                  desc: res.Msg,
                });
              }
            } catch (error) {
              console.log(error);
              this.$Notice.error({
                title: "错误",
                desc: error,
              });
              this.SaveLoading = false;
            }
          } else {
            this.handleSave();
          }
        }
      });
    },
    onChange(opt) {
      this.uploadFile = opt.files;
    },
    async handleSave() {
      const _this = this;
      this.SaveLoading = true;
      try {
        if (
          _this.uploadFile.length &&
          _this.uploadFile.filter((f) => !f.success).length
        ) {
          const res = await _this.$refs.UploadAvatar.Upload();
          if (res.success.length === 0) {
            _this.SaveLoading = false;
            return _this.$Message.error("LOGO上传失败");
          } else {
            _this.selectedObj.Avatar = res.success[0];
          }
        }
        const res = await api.SaveUser({
          user: this.selectedObj,
        });
        if (res.Status === 100) {
          this.SaveLoading = false;
          this.$Message.success("操作成功");
          this.userView = false;
          let index = this.resObj.DataList.findIndex(
            (item) => item.ID === this.selectedObj.ID
          );
          if (index >= 0) {
            this.resObj.DataList.splice(index, 1, res.Data);
          }
        } else {
          this.$Notice.error({
            title: "错误",
            desc: res.Msg,
          });
          this.userView = false;
        }
      } catch (error) {
        console.log(error);
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.userView = false;
        this.SaveLoading = false;
      }
    },
    handleSearch() {
      this.params.Page = 1;
      this.loadList();
    },
    async getAllRoleList() {
      try {
        const res = await api.GetSysRoleList({});
        if (res.Status === 100) {
          this.allRoleList = res.Data;
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
      }
    },
    async handleOpenRoles(row) {
      try {
        const res = await api.GetRoleListByUser({
          userid: row.ID,
        });
        if (res.Status === 100) {
          var list = JSON.parse(res.Data);
          if (list.length) {
            var now = [];
            list.forEach((item) => {
              if (now.indexOf(item.RoleID)) now.push(item.RoleID);
            });
            this.NowCheckListSource = JSON.parse(JSON.stringify(now));
            this.NowCheckList = now;
          } else {
            this.NowCheckListSource = [];
          }
          this.setView = true;
          this.selectedObj = row;
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
      }
    },
    async handleSaveRoles() {
      try {
        if (this.AddCheckList.length || this.DelCheckList.length) {
          this.SaveLoading = true;
          const res = await api.SetRoleByUser({
            userid: this.selectedObj.ID,
            addlist: this.AddCheckList,
            dellist: this.DelCheckList,
          });
          if (res.Status === 100) {
            this.NowCheckList = [];
            this.NowCheckListSource = [];
            this.setView = true;
            this.selectedObj = {};
            this.$Message.success("保存成功");
            this.SaveLoading = false;
            this.setView = false;
          } else {
            this.$Message.error(res.Msg);
            this.setView = false;
          }
        } else {
          this.setView = false;
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.SaveLoading = false;
      }
    },
    // 选项被改变
    selectChangeList(checklist) {
      const _this = this;
      _this.AddCheckList = [];
      _this.DelCheckList = [];
      if (checklist) {
        checklist.map((item) => {
          if (_this.NowCheckListSource.indexOf(item) === -1) {
            _this.AddCheckList.push(item);
          }
        });
      } else {
        _this.AddCheckList = [];
      }
      if (_this.NowCheckListSource) {
        _this.NowCheckListSource.map((item) => {
          if (checklist.indexOf(item) === -1) {
            _this.DelCheckList.push(item);
          }
        });
      } else {
        _this.DelCheckList = [];
      }
    },
    // 远程排序
    sortChange(result) {
      this.params.SortName = result.key;
      this.params.SortOrder = this.params.SortOrder === "Desc" ? "Asc" : "Desc";
      this.loadList();
    },
    handleReset() {
      this.$refs.SearchForm.resetFields();
      this.loadList();
    },
    handleChangePageSize(pagesize) {
      this.params.Page = 1;
      this.params.PageSize = pagesize;
      this.loadList();
    },
    handleChangePage(page) {
      this.params.Page = page;
      this.loadList();
    },
  },
  created() {
    if (this.$route.meta.tableActionWidth) {
      let addition = 0;
      if (this.$canAction(["Sys_User_SetRole"])) addition += 25;
      this.columns.push({
        title: "操作",
        key: "action",
        slot: "Action",
        align: "center",
        minWidth: this.$route.meta.tableActionWidth + addition,
        maxWidth: this.$route.meta.tableActionWidth + addition,
        fixed: "right",
      });
    }
    this.getAllRoleList();
    this.loadList();
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
    document.onkeydown = function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.loadList();
      }
    };
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);
        this.getAllRoleList();
        this.loadList();
      }
    });
  },
  activated() {
    const self = this;
    window.addEventListener("keydown", function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.loadList();
      }
    });
  },
  deactivated() {
    window.removeEventListener("keydown", function () {});
  },
  computed: {
    uploadType() {
      return bizEnum.UploadType;
    },
  },
  components: { iUpload, layout },
};
</script>

<style lang="less">
.s_u_modal_box {
  width: 30%;
  white-space: nowrap;
}
</style>
